import React from "react"; import styles from "../sass/components/Button.module.scss";

interface ButtonProps {
    type?: "primary";
    variant?: "filled" | "outlined"
    link?: string;
    target?: string |undefined,
    children?: React.ReactNode;
}


export default class Button extends React.Component<ButtonProps> {
    static defaultProps: ButtonProps = {
        type: "primary",
        variant: "filled"
    }

    render() {
        const { link, children, type, variant, target} = this.props
        
        const classes = [
            styles.button, 
            type === "primary" && styles.primary,
            variant === "filled" && styles.filled,
            variant === "outlined" && styles.outlined,
        ].filter(Boolean).join(" ");

        return (
            <a href={ link } target={target}><button className={classes}>{ children }</button></a>
        )
    }
}