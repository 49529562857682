import React from "react";

import styles from "../../sass/components/Menu.module.scss";

import Button from "../Button";
import MenuLink from "./MenuLink";

export default function DesktopMenu() {
   
    return (
        <div className={styles.desktop_menu}>
            <ul>
                <MenuLink to="/">Hem</MenuLink>
                <MenuLink to="/services">Tjänster</MenuLink>
                <MenuLink to="https://www.bokadirekt.se/places/ekholms-rekond-60968" target="_blank"><Button>Boka idag</Button></MenuLink>
            </ul>
        </div>
    )
}