export const services = [
    {
        "title": "Dragkroksmontering",
        "description": "",
        "time": "500 minuter",
        "price": undefined,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/dragkroksmontering-3191515",
    },
    {
        "title": "Biltvätt",
        "description": "",
        "time": "30 minuter",
        "price": 540,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/biltvatt-3191521",
    },
    {
        "title": "Invändig Tvätt",
        "description": "",
        "time": "60 minuter",
        "price": 500,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/invandig-tvatt-3183939",
    },
    {
        "title": "Invändig rekond",
        "description": "",
        "time": "240 minuter",
        "price": 1290,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/invandig-rekond-3187237",
    },
    {
        "title": "Keramiskt lackskydd",
        "description": "",
        "time": "480 minuter",
        "price": 5990,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/keramiskt-lackskydd-3187250",
    },
    {
        "title": "Motortvätt",
        "description": "",
        "time": "30 Minuter",
        "price": 400,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/motortvatt-3187232",
    },
    {
        "title": "Ozonbehandling",
        "description": "",
        "time": "240 Minuter",
        "price": 1000,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/ozonbehandling-3187262",
    },
    {
        "title": "Rekond in & utvändigt",
        "description": "",
        "time": "480 Minuter",
        "price": 3750,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/rekond-in-utvandigt-3187220",
    },
    {
        "title": "Solfilm",
        "description": "",
        "time": "480 Minuter",
        "price": 4500,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/solfilm-3187270",
    },
    {
        "title": "Tvätt och städ",
        "description": "",
        "time": "90 Minuter",
        "price": 1100,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/tvatt-stad-3187227",
    },
    {
        "title": "Utvändig Rekond",
        "description": "",
        "time": "240 minuter",
        "price": 2990,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/utvandig-rekond-3184039"
    },
    {
        "title": "Bedömning/felsökning av fordon",
        "description": "",
        "time": "30 Minuter",
        "price": 500,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/bedomning-felsokning-av-fordon-3187240",
    },
    {
        "title": "Bromsbyte personbil",
        "description": "",
        "time": "240 Minuter",
        "price": undefined,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/bromsbyte-personbil-3191514",
    },
    {
        "title": "Fukttest Husvagn/Husbil",
        "description": "",
        "time": "240 Minuter",
        "price": 900,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/fukttest-husvagn-husbil-3187267",
    },
    {
        "title": "Oljeservice Personbil",
        "description": "",
        "time": "90 Minuter",
        "price": 1790,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/olje-service-personbil-3187244",
    },
    {
        "title": "Ozonbehandling",
        "description": "",
        "time": "240 Minuter",
        "price": 2290,
        "link": "https://www.bokadirekt.se/boka-tjanst/ekholms-rekond-60968/oljeservice-husbil-3191513",
    },
]