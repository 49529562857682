import { AnimatePresence, motion } from "framer-motion";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useLocation } from "react-router-dom";

import styles from "../../sass/components/Menu.module.scss";

import MobileNavLink from "./MenuLink";

export default function MobileMenu(props: { active: boolean; setActive: Dispatch<SetStateAction<boolean>> }) {

    const location = useLocation()

    useEffect(() => {
        if(props.active)
            props.setActive(false);

    }, [location])
    
    useEffect(() => {
        if (props.active) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = ""; 
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [props.active]);

    return (
        <AnimatePresence>
            {props.active && (
                <motion.div
                    className={styles.mobile_menu}
                    style={{
                        top: "100%",
                        left: "0",
                        originY: 0,
                    }}
                    variants={{
                        initial: { scaleY: 0 },
                        animate: {
                            scaleY: 1,
                            transition: {
                                duration: 0.5,
                                ease: "easeInOut",
                            },
                        },
                        exit: {
                            scaleY: 0,
                            transition: {
                                delay: 0.3,
                                duration: 0.5,
                                ease: "easeInOut",
                            },
                        },
                    }}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <motion.ul
                        variants={{
                            closed: { 
                                opacity: 0,
                                transition: {
                                    staggerChildren: 0.09,
                                    staggerDirection: -1,
                                } 
                            },
                            open: {
                                opacity: 1,
                                transition: {
                                    delayChildren: 0.3,
                                    staggerChildren: 0.09,
                                    staggerDirection: 1
                                },
                            },
                        }}

                        initial="closed"
                        animate="open"
                        exit="closed"
                    >
                        <MobileNavLink key="home" to="/">Hem</MobileNavLink>
                        <MobileNavLink key="services" to="/services">Tjänster</MobileNavLink>
                        <MobileNavLink key="book" to="https://www.bokadirekt.se/places/ekholms-rekond-60968" target="_blank">Boka idag</MobileNavLink>
                    </motion.ul>
                </motion.div>
            )}
        </AnimatePresence>
    );
}