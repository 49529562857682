import React, { useEffect, useState } from "react"

import styles from "../sass/screens/HomeScreen.module.scss";

import Block from "../components/Block";
import Slider from "../components/Slider";
import ServiceCard from "../components/ServiceCard";
import Button from "../components/Button";



export default function HomeScreen() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const data = [
        {
            title: "Rekonditionering",
            description: `
            En komplett fordonsvård som lyfter fram dess skönhet 
            både inuti och utanpå. Med vår noggranna rengöring, 
            professionella vaxning och polering får du inte bara 
            en skinande glans, utan du förlänger även bilens 
            livslängd.`,
            img: "./img/rekond.jpg"
        },
        {
            title: "Keramiskt Lackskydd",
            description: `
            En komplett fordonsvård som lyfter fram dess skönhet både inuti och utanpå. 
            Med vår noggranna rengöring, professionella vaxning och polering får du inte 
            bara en skinande glans, utan du förlänger även bilens livslängd.`,
            img: "./img/keramisktlackskydd.jpg"
        },
        {
            title: "Ozonbehandling",
            description: `
            Eliminerar dålig lukt, oangenäma dofter samt mikroorgansimer från ditt fordon. 
            Genom att ta bort mögelsporer och bakterier som ofta trivs i trånga utrymmen 
            blir ditt fordon luktfritt.`,
            img: "./img/ozonbehandling.jpg"
        },
        
    ]
    
    useEffect(() => {
        window.addEventListener("resize", handleReszie)

        return () => { window.removeEventListener("resize", handleReszie) }
    }, []);

    function handleReszie() {
        setWidth(window.innerWidth);
    }

    let isMobile: boolean = width <= 800 ? true : false;

    return <>
        <Block>
            <div className={styles.hero_container}>
                <div className={styles.overlap}>
                    <div className={styles.info_container}>
                        <h3>Ge din bil ett nytt leende</h3>
                        <div className={styles.button_container}>
                            <div className={styles.line}></div>
                            <Button variant="outlined" link="https://www.bokadirekt.se/places/ekholms-rekond-60968" target="blank">Boka Idag</Button>
                        </div>
                    </div>
                    <Slider />
                </div>
            </div>
        </Block>
        <div className={styles.feature_container}>
            <Block fullbleed={isMobile}>
                <p>
                    Här på Ekholms Rekond återställer vi inte bara utseendet; 
                    vi skapar en upplevelse. Få känslan av ett fordon i perfektion 
                    och ett extra leende - Boka din tid redan idag!
                </p>
                <Button link="https://www.bokadirekt.se/places/ekholms-rekond-60968" target="blank">Boka här</Button>
            </Block>
        </div>
        <Block>
            <div className={styles.card_container}>
                {data.map((item, index: number) => (
                    <ServiceCard key={index} data={item}/>
                ))}
            </div>
        </Block>
    </>
}