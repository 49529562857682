import React from "react";
import styles from "../sass/components/Footer.module.scss";
import Block from "./Block";

export default class Footer extends React.Component {

    render() {
        return (
            <footer className={styles.footer}>
                <Block>
                    <div className={styles.overlap}>
                        <div className={styles.contact_container}>
                            <div className={styles.info}>
                                <img src="/img/icons/phone.svg" alt="" />
                                <a href="tel:0767952956">0767952956</a>
                            </div>
                            <div className={styles.info}>
                                <img src="/img/icons/mail.svg" alt="" />
                                <a href="mailto:ekholmsrekond@outlook.com">ekholmsrekond@outlook.com</a>
                            </div>
                            <div className={styles.info}>
                                <img src="/img/icons/location.svg" alt="" />
                                <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4659fb28b8281417:0xa0a4c727efe952?sa=X&ved=1t:8290&ictx=111">
                                    Kvarnarp Vägverket 1
                                </a>
                            </div>
                            <div className={styles.info}>
                                <img src="/img/icons/schedule.svg" alt="" />
                                <a>Vardagar 07:00 - 17:00</a>
                            </div>
                        </div>
                        <div className={styles.sm_container}>
                        <div className={styles.info}>
                                <a href="https://www.instagram.com/ekholms_rekond/">
                                    <img src="/img/icons/instagram.svg" alt="" />
                                </a>
                            </div>
                            <div className={styles.info}>
                                <a href="https://www.facebook.com/p/Ekholms-Rekond-AB-100070813173800/">
                                    <img src="/img/icons/facebook.svg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </Block>
            </footer>   
        )   
    }
}