import React from "react"
import styles from "../sass/components/Service.module.scss";

import Button from "./Button";

interface ServiceProps {
	data: any;
}


export default class Service extends React.Component<ServiceProps> { 
	static defaultProps: ServiceProps = {
		data: {}
	}

	render() {
		const { data } = this.props;

		return (
			<div className={styles.service}>
				<div className={styles.overlap}>
					<div className={styles.info_container}>
						<h6>{ data.title }</h6>
						<span>{ data.time }</span>
					</div>
					<div className={styles.book_container}>
						{ data.price ? (
							<span>Från {data.price} kr</span>
							) : (
								<span>Kontakta oss för offert</span>
							) }
						<Button link={data.link} target="blank">Boka</Button>
					</div>
				</div>
			</div>
		)
	}
}
